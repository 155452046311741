import { Typography, Box, Button, Tabs, Tab,  Card, CardActions, CardMedia, CardContent, Rating } from "@mui/material";
import React from "react";
import RamadanDecor from "../public/ramadan-main-page.png"
import PageHeader from "../components/PageHeader";
import EstablishmnetCard from "../components/EstablishmentCard";
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: {md:"24px", xs:"15px" }}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const classes = {
    container: {
        minHeight: "70vh"
    },

    establishmentsContainer: {
        display: "flex", 
        gap: "15px", 
        flexWrap: "wrap"
    }
}

export default function EstablishmentsPage(){
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={classes.container}>
            <PageHeader header={"Establishments"}/>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: {md:"24px"}}}>
                <Tabs variant="scrollable"  value={value} onChange={handleChange} >
                    <Tab label="Mosques" {...a11yProps(0)} />
                    <Tab label="NonProfit" {...a11yProps(1)} />
                    <Tab label="Businesses" {...a11yProps(2)} />
                    <Tab label="Services (coming soon)" {...a11yProps(3)} disabled />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box sx={classes.establishmentsContainer}>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Muslim Community Organization" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box sx={classes.establishmentsContainer}>
                    <EstablishmnetCard name="Minterns" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box sx={classes.establishmentsContainer}>
                    <EstablishmnetCard name="Barber" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.2} image={RamadanDecor}/>
                    <EstablishmnetCard name="Gym" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                    <EstablishmnetCard name="Something Else" location="https://goo.gl/maps/3i4Y7tVjt6egS6bG8" address="3003 Scott Blvd, Santa Clara" rating={4.6} image={RamadanDecor}/>
                </Box>
            </TabPanel>
        </Box>
    )
}