import React from "react";
import { Routes, Route } from "react-router";
import HomePage from "./HomePage";
// import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import NavigationBar from "../components/NavigationBar";
import { Container } from "@mui/system";
import NavBar from "../components/NavBar/NavBar";
import RamadanPage from "./RamadanPage";
import ResturantsPage from "./ResturantsPage";
import EstablishmentsPage from "./EstablishmentsPage";
import AboutUsPage from "./AboutUsPage";
import ContactUsPage from "./ContactUsPage";
import Footer from "../components/Footer";

const classes = {
    container :{
        boxShadow: 20, 
        maxWidth: 1500, 
    }
}


export default function NavigationPageWrapper() {
    return (
        <Container sx={classes.container}  disableGutters>
            <NavBar/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/ramadan" element={<RamadanPage/>}/>
                <Route path="/resturants" element={<ResturantsPage/>}/>
                <Route path="/establishments" element={<EstablishmentsPage/>}/>
                <Route path="/about-us" element={<AboutUsPage/>}></Route>
                <Route path="/contact" element={<ContactUsPage/>}></Route>
            </Routes>
            <Footer/>
        </Container>
    )
}

