import React from "react";
import { Typography, Box, Button, Card, CardActions, CardMedia, CardContent, Rating } from "@mui/material";
import RamadanDecor from "../public/ramadan-main-page.png"
import GoogleIcon from '@mui/icons-material/Google';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useTheme} from "@mui/material";
const classes = {

    cardMedia: {
        height: 100, 
    }, 
    card: {
        // minWidth: {md:"213px", xs: "40%"},
        width: {md:"18.95%", sm:"23%", xs:"47.7%"} 
    }, 
    detailItem: {
        display: "flex", 
        flexDirection: "row", 
        gap: {md:"10px", sm:"5px", xs: "10px"},
        fontSize: "0.6rem"
    }, 
    details: {
        display: "flex", 
        flexDirection: "column", 
        gap: "10px", 
    }, 
    rating: {
        size: {md: "", xs:"small"}
    }, 
    title: {
        fontSize: {md: "1.25rem",xs: "0.9rem"}
    }, 
    cardContent: {
        p: 1
    }
}

export default function EstablishmnetCard({name, location, address, rating, image}) {
    const theme = useTheme();
    return (
        <Card sx={classes.card}>
            <CardMedia
            image={image}
            sx={classes.cardMedia}>

            </CardMedia>
            <CardContent sx={classes.cardContent}>
                <Typography sx={classes.title} gutterBottom variant="h6" component="div">
                    {name}
                </Typography>
                <Box sx={classes.details}>
                    <Box sx={classes.detailItem}>
                        <LocationOnIcon sx={{color: "#a40000"}}/>
                        <a href={location}>
                            <Typography component="div" sx={{fontSize: {md:"0.8rem", xs:"0.7rem"}}}>{address}</Typography>
                        </a>
                    </Box>
                    <Box sx={classes.detailItem}>
                        <GoogleIcon sx={{color: "#0048a2"}}/>
                        <Rating readOnly value={rating} sx={classes.rating} size={theme.breakpoints.down("md")?"small": ""} precision={0.1}/>
                    </Box>
                </Box>
            </CardContent>
            <CardActions>
                <Button color="secondary" variant="outlined" size={theme.breakpoints.down("md")?"small": ""}>Learn More</Button>
            </CardActions>
        </Card>
    )
}