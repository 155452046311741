import React from "react";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import { Button, IconButton, Link, List, ListItem, ListItemButton, TextField, Typography } from "@mui/material";
import { Facebook, FacebookOutlined, Instagram, Twitter } from "@mui/icons-material";

const classes = {
    footerContainer: {
        color: grey[700], 
        pt: "2rem",
        borderTop: "2px solid #dadada",
        // pb: "3px"
        px: {md:'4rem', xs: '15px'}, 
    }, 
    mainSection: {
        display: "flex", 
        flexDirection: {md: "row", xs:"column"},
        gap: "2rem", 
        mb: "2rem"
    },
    muslimFriendly: {
        flex: 2, 
        width: "100%"
    }, 
    someLinks: {
        flex: 1, 
        width: "100%"
    }, 
    newsLetter: {
        flex: 2, 
        width: "100%"
    }, 
    socialMedia: {
        display: "flex", 
        gap: "1rem",
        flexDirection: "row", 
        mt: "1rem"
    }, 
    header: {
        display: "block", 
        fontSize: "1.17em",
        marginBlockStart: "1em",
        marginBlockEnd: "1em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
    }, 
    links :{
        display: "flex",
        flexDirection: "column"
    }, 
    link: {
        color: "inherit", 
        textDecoration: "none",
        "&:hover": {
            color: "primary.main", 
            textDecoration: "underline"
        }
    }, 
    allRightReserved: {
        width: "100%", 
        // backgroundColor: "#202020", 
        color: grey[700]
    }, 
    subscribe: {
        display: "flex", 
        flexDirection: "column"
    }
}
export default function Footer() {
    return (
        <Box sx={classes.footerContainer}>
            <Box sx={classes.mainSection}>
                <Box sx={classes.muslimFriendly}>
                    <Box sx={classes.letsConnect}>
                        <Typography component={"h1"} sx={classes.header}>
                            Muslim Friendly
                        </Typography>
                        <Typography sx={classes.text}>
                            Let's connect over social media. We want to hear your feedback and get to make our services better.
                        </Typography>
                    </Box>
                    <Box sx={classes.socialMedia}>
                        <Button variant="outlined"
                            id="facebook"
                        >
                            <Facebook/>
                        </Button>
                        <Button variant="outlined"
                            id="instagram"
                        >
                            <Instagram/>
                        </Button>
                        <Button variant="outlined"
                            id="twitter"
                        >
                            <Twitter/>
                        </Button>
                    </Box>
                </Box>
                <Box sx={classes.someLinks}>
                    <Typography sx={classes.header}>
                        Some Links
                    </Typography>
                    <Box sx={classes.links}>
                        <Link sx={classes.link} color="primary">
                            Our Policy
                        </Link>
                        <Link sx={classes.link} color="primary">
                            F.A.Q
                        </Link>
                        <Link sx={classes.link} color="primary">
                            Report Something
                        </Link>
                    </Box>
                </Box>
                <Box sx={classes.newsLetter}>
                    <Typography sx={classes.header}>
                        Join Our Newsletter
                    </Typography>
                    <Box sx={classes.subscribe}>
                        <TextField sx={classes.subscribeInput} variant="filled" color="primary" label="your email address here"></TextField>
                        <Button variant="contained" color="primary" sx={classes.subscribeBtn}>
                            Subscribe
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Typography sx={classes.allRightReserved}>
            © 2023 Muslim Friendly. All rights reserved.
            </Typography>
        </Box>
    )
}