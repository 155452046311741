import { Autocomplete, Button, IconButton } from "@mui/material";
import React from "react";
import TextField from '@mui/material/TextField';
import { Box } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';






const classes = {
    search: {
        flex: 10,
        color: "secondary.main",
        backgroundColor: "background.default"
    }, 
    button: {
        backgroundColor: "secondary.main",
        '&:hover': {
          backgroundColor: "#8b2f03",
          boxShadow: 4
        },
        boxShadow: "none", 
        flex: 1
    }, 
    searchHome: {
        display: 'flex',
        px: {md: "4rem"},
        maxWidth: {lg:'1000px', xs: '100%'}, 
        mx: 'auto', 
        backgroundColor: "background.default"

    }
}

export default function SearchHome() {
    return (
        <Box sx={classes.searchHome}>

                <Autocomplete
                
                sx={classes.search}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField variant="filled" color="secondary" {...params} label="Find muslim friendly near me." />}
                >
                    
                </Autocomplete>
                <Button sx={classes.button}
                startIcon={<SearchIcon sx={{ color: 'white' }}/>}>

                </Button>
        </Box>
    )
}