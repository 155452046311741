import React, {useState} from 'react';
import { IconButton,
    SwipeableDrawer, 
    Box, 
    List, 
    Typography, 
    Divider 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import '../../styles/NavBar.css';
import NavLink from './NavLink';

const classes = {
    navMenu : {
        display: { xs: 'block', md: 'none'},
    }, 
    title: {
        fontSize: '2rem', 
        color: 'primary.main', 
        mx: 3, 
        mt: 3
    }, 
    navLinksHolder: { 
        width: 250, 
        mx: 3 
    }
}
function NavMenu({pages}) {
    const [open, setOpen] = useState(false);

    return (
        <Box sx={classes.navMenu}>
            <IconButton onClick={() => setOpen(true)}>
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                anchor='right'
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <Typography
                    component="h1"
                    sx={classes.title}
                >
                    Menu Items
                </Typography >
                <Divider/>
                <Box
                    sx={classes.navLinksHolder}
                    role="presentation"
                    onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}
                    >
                    <List>
                        {pages.map((page, index) => (
                        <NavLink
                            label={`${index+1}. ${page.label}`}
                            route={page.route}
                            key={page.route}
                        />
                        ))}
                    </List>
                    </Box>
            </SwipeableDrawer>
        </Box>
    );
};
export default NavMenu;
