import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import RamadanDecor from '../public/muslims.png'
import MuslimFriendlyLogo from '../public/muslim-friendly.png'
import "../../src/styles/HomePage.css"
import SearchHome from "../components/SearchHome";
import NavBar from "../components/NavBar/NavBar";
import { grey } from "@mui/material/colors";

const classes = {
    leftSection: {
        flex: 3,
        display: 'flex', 
        flexDirection: 'column',
        minHeight: {md:'40vh', xs: '20vh'}, 
        pl: {md:"4rem"}
    }, 
    rightSection: {
        flex: {md: 2, xs: 0},
    },
    preSearchSection: {
        display: 'flex', 
        flexDirection: 'row'
    }, 
    logoContainer: {
        display: {md: "block", xs: "none"},
        flex: 2, 
    },
    logo: {
        width: 'auto', 
        height: '15vh'
    }, 
    ramadanDecor: {
        width: '100%', 
        height: 'auto',
        transform: 'scaleX(-1)', 
        display: {md: 'block', xs: 'none'}
    }, 
    discover: {
        fontSize: {md:"3rem", xs: '2rem'}, 
        fontWeight: "bold",
        fontFamily: "\"Arimo\", sans-serif"
    }, 
    lens: {
        fontSize: {md: "2.5rem", xs: '1.5rem'}, 
        fontFamily: "\"Arimo\", sans-serif"
    }, 
    list: {
        color: "#f3e8d7a8",
        fontSize: {md: "1.5rem", xs: '0.9rem'}, 
        fontWeight: "100",
        fontFamily: "\"Arimo\", sans-serif"
    }, 
    globalEmptyBox: {
        height: {md:"5rem", xs: '3rem'}
    }, 
    headlineBox: {
        flex: 3, 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left', 
        justifyContent: 'space-around', 
        color: "white"
    }, 
    welcome: {
        backgroundColor: "#1b3466  ", 
        px: {md: 0, xs:'15px'}, 
    }, 

}

export default function WelcomeSection() {
    return(
        <Box sx={classes.welcome}>
        <Box sx={classes.preSearchSection}>
            <Box sx={classes.leftSection} >
                <Box sx={classes.logoContainer}>
                    <Box
                        sx={classes.logo}
                        component="img"
                        src={MuslimFriendlyLogo}
                    />
                </Box>
                <Box sx={classes.headlineBox}>
                    <Box>
                        <Typography component="h1" sx={classes.discover}>
                            Discover Silicon Valley
                        </Typography>
                        <Typography component="h3" sx={classes.lens}>
                            through a <strong style={{color: "#C59849"}}>muslim friendly</strong> lens.
                        </Typography>
                        <Typography  component="pre" sx={classes.list}>
                            Resturants    <strong style={{color: "white"}}>.</strong>    Schools    <strong style={{color: "white"}}>.</strong>    Barbers    <strong style={{color: "white"}}>.</strong>    And More
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={classes.rightSection}>
                <Box 
                component="img"
                src={RamadanDecor}
                sx={classes.ramadanDecor}
                />
            </Box>

        </Box>
        <SearchHome/>
        <Box sx={classes.globalEmptyBox}>
        </Box>
    </Box>
    )
}