import * as React from 'react';
import NavLink from './NavLink';
import '../../styles/NavBar.css';
import {Container, 
  Toolbar, 
  Box } from '@mui/material';
import NavMenu from './NavMenu';
import MuslimFriendlyLogo from  "../../public/muslim-friendly.png"
import { useLocation } from 'react-router-dom'
const pages = [
  {label:'Ramadan', route:'/ramadan'}, 
  {label:'Resturants', route:'/resturants'}, 
  {label:'Establishments', route:'/establishments'}, 
  {label:'About Us', route:'/about-us'}, 
  {label:'Contact Us', route:'/contact'}
];
const classes ={
  navContainer:{
    boxShadow: '0px 15px 10px -15px primary.main', 
  },
  navBarCont: { 
    ml: {md:"auto"}, 
    mr: {md:0},
    flexGrow: 0, 
    display: 'flex',
    marginRight: 0
  }, 
  navBar: { 
    flexGrow: 1, 
    display: { xs: 'none', md: 'flex'},
    justifyContent:'space-between'
  }, 
  logoContainerXS: {
    display: {md: "none", xs: "block"}, 
    pl: {md:"24px"}
  },
  logoContainerMD: {
    display: {md: "block", xs: "none"}, 
    pl: {md:"24px"}
  },
  logo: {
      width: 'auto', 
      height: '10vh'
  }, 
}

function NavBar() {
  const path = useLocation().pathname;

  return (
    <Box >
      <Container maxWidth="l" sx={classes.navContainer} disableGutters>
        <Toolbar disableGutters sx={{justifyContent:'space-between'}}>
         {
           <Box sx={classes.logoContainerXS}>
            <a href="/">
                <Box
                    sx={classes.logo}
                    component="img"
                    src={MuslimFriendlyLogo}
                    
                />
            </a>
          </Box>
         }
         {
          path !== "/" && <Box sx={classes.logoContainerMD}>
            <a href="/">
                <Box
                    sx={classes.logo}
                    component="img"
                    src={MuslimFriendlyLogo}
                    
                />
            </a>
          </Box>
         }
          <Box sx={classes.navBarCont}>
            <Box sx={classes.navBar}>
                {pages.map((page) => (
                <NavLink
                    label={page.label}
                    route={page.route}
                    key={page.route}
                />
                ))}
            </Box>
            <NavMenu pages={pages}/>
          </Box>
           
        </Toolbar>
      </Container>
    </Box>
  );
};
export default NavBar;
