import React from "react";
import { Container} from "@mui/material";
import "../../src/styles/HomePage.css"
import { useTheme } from '@mui/material/styles';
import WhatWeDoSection from "../components/WhatWeDoSection";
import WelcomeSection from "../components/WelcomeSection";
import RamadanSection from "../components/RamadanSection";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer";


export default function HomePage() {
    const theme = useTheme();
    return (
        <>
            <WelcomeSection/>
            <WhatWeDoSection/>
            <RamadanSection/>
        </>
    )
}