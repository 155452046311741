import React from "react";
import { Box } from "@mui/system";
import { Button, Typography, Card, CardActions, CardMedia, CardContent } from "@mui/material";
import RamadanDecor from '../public/ramadan-main-page.png'
import Fireworks from '../public/fireworks.png'
const classes = {
    blueBox: {
        backgroundColor: "black",
        py: "7rem", 
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        background: "linear-gradient(135deg, #4f4f4f 0%, #202020)"
    }, 
    ramadan: {
        fontSize: "90px",
        fontFamily: "'Great Vibes', cursive;",
        lineHeight: "60px",
        color: "#9C7649",
        p:0
    }, 
    kareem: {
        color: "#d5a66e", 
        fontSize: "60px",
        fontFamily: "\'Titillium Web\', sans-serif",
        p: 0, 
        lineHeight:"60px",
        wordSpacing: "0px",
    },
    blueBoxContent: {
        mx: {md:'4rem', xs: '15px'}, 
    }, 
    cardMedia: {
        height: 140, 
    }, 
    eventsHolder: {
        display: "flex", 
        flexDirection: {md: "row", xs: "column"} ,
        justifyContent: "space-between", 
        gap: '15px', 
        mt: "1rem"
    }, 
    description: {
        color: "#fff0de", 
        mt: "2rem", 
        fontSize: "25px", 
        fontFamily: "\'Titillium Web\', sans-serif",
    }

}

export default function RamadanSection() {
    return (
        <Box sx={classes.blueBox}>
            <Box sx={classes.blueBoxContent}>

                <Typography sx={classes.ramadan}>
                    Ramadan 
                </Typography>
                <Typography component={"pre"} sx={classes.kareem}>
                    M u b a r a k 
                </Typography>
                <Typography component={"p"} sx={classes.description}>
                    Checkout Ramadan activity in the bay area.
                </Typography>
                <Box sx={classes.eventsHolder}>
                    {
                        [1, 2, 3].map(() => (
                            <Card sx={{ maxWidth: {md:330 }, backgroundColor: "#fff3d6b5"}}>
                                <CardMedia
                                    sx={classes.cardMedia}
                                    image={RamadanDecor}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Ramadan
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Ramadan is a wordwide event with over 1 billion people observing it, come learn more about it.
                                    Bring your friends and family, food is served daily at sunset.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button color="secondary" variant="outlined">Learn More</Button>
                                </CardActions>
                            </Card>
                        ))
                    }
                </Box>
                <br/>
                <Button sx={{float: "right"}} variant="contained" color="secondary">{`show more events`}</Button>
                
            </Box>
        </Box>
    )
}