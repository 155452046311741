import React from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom'
import { useLocation } from 'react-router-dom'

function NavLink ({route, label}) {
    const path = useLocation().pathname;
    return (
        <Link to={route} style={{textDecoration: 'none', color: 'inherit'}}>
            <Typography
                className='nav-link'
                underline='none'
                variant='h6'
                sx={{ my: 2, 
                    marginRight: 3, 
                    fontSize: "15px",
                    color: path.includes(route)? 'primary.main':'text.primary',
                    display: 'flex', 
                    '&:hover': {
                        color: 'primary.main'
                    },
                    '&::after': path.includes(route)? {
                        content: '""',
                        position: 'absolute',
                        width: "100%",
                        height: '2px',
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'primary.main'
                      } : {
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        transform: 'scaleX(0)',
                        height: '2px',
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'primary.main',
                        transformOrigin: 'bottom right',
                        transition: 'transform 0.25s ease-out',
                      },
                    fontFamily: '"Roboto", sans-serif'
                }}
            >
                {label}
            </Typography>
        </Link>
    )
};

export default NavLink;