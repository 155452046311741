import { ThemeProvider } from '@emotion/react';
import NavigationPageWrapper from './pages/NavigationPageWrapper';
import CssBaseline from "@mui/material/CssBaseline";
import { Paper } from '@mui/material';
import {createTheme} from '@mui/material';

function App() {

  const theme = createTheme({
    palette: {
      background: {
        paper: "#F5F6F7", 
        default: "#ffff", 
        container: "#C59849"
      },
      primary: {
        main: "#0071A8",
        second: "#C59849"
      }, 
      secondary: {
        main: "#A83700"
      }, 
      ramadan: {
        main: "#C59849"
      }
    }
  })

  console.log(theme)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper sx={{boxShadow: 'none'}}>
        <NavigationPageWrapper/>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
