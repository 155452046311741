import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";

import "../../src/styles/HomePage.css"
import { grey, yellow } from "@mui/material/colors";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ThreePIcon from '@mui/icons-material/ThreeP';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';


const classes = {
    whatIsMF: {
        mx: {md:'4rem', xs: '15px'},
        my: '5rem'
    }, 
    missionTitle: {
        flex: 1, 
        justifyContent: "space-between"
    },
    ourMission: {
        display: "flex",
        flexDirection: {md:"row", xs: "column"},
        gap: {md:"3rem", xs: "1rem"}
    },
    beMuslim:{
        fontSize: "32px", 
        fontWeight: "bold", 
    }, 
    whatWeDo: {
        flex: 1, 
        lineHeight: "30px", 
        color: grey[700], 
        wordSpacing:"3px",
        fontSize: "20px",
        textJustify: "inter-character"
    }, 
    introMissionTitle: {
        color: "grey"
    }, 
    ourServices:{
        display: "flex", 
        flexDirection: {md:"row", xs: "column"},
        gap: {md:"1rem", xs: "1rem"}, 
        justifyContent: "space-between", 
        mt: {md:"5rem", xs:"3rem"},
    }, 
    serviceBox:{
        flex: 1, 
        width: "100%", 
        // height: "10rem", 
        backgroundColor: "white",
        boxShadow: "0px 5px 29px -17px rgba(0,0,0,0.58)",
        // boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        // border: "2px solid ", 
        '&:hover': {
            backgroundColor: grey[100],
            
          },
        // borderRadius: "10px", 
        padding: "1rem"
    }, 
    comingSoon:{
        flex: 1, 
        width: "100%", 
        backgroundColor: "#efefef",
        // border: "2px solid ", 
        // '&:hover': {
        //     backgroundColor: grey[200],
        //     boxShadow: 4
        //   },
        // borderRadius: "10px", 
        padding: "1rem"
    }, 
    serviceHeader: {
        display: "flex", 
        gap: "20px", 
        color: "#0071A8",
    }, 
    gold: {
        color: yellow[900]
    }, 
    serviceDescription: {
        mt: "1.5rem"
    }
}
export default function WhatWeDoSection() {

    return (
        <Box sx={classes.whatIsMF}>
            <Typography sx={classes.introMissionTitle}>WHAT WE DO</Typography>
            <Box sx={classes.ourMission}>
                <Box sx={classes.missionTitle}>
                    <Typography component="h4" sx={classes.beMuslim}>Help Us Create More Muslim Friendly Spaces</Typography>
                    <Button color="secondary" variant="contained">Make an Entry</Button>
                </Box>
                <Typography sx={classes.whatWeDo}>We gather data about local resturants, businesses, esbalishmnets, offices, and educational facilities to provide you ratings on how they align with Muslim practices and values.</Typography>
            </Box>

            <Box sx={classes.ourServices}>
                <Box sx={classes.serviceBox}>
                    <Box sx={classes.serviceHeader}>
                        <MarkChatReadIcon fontSize="large"/>
                        <Typography>Friendly Checklist</Typography>
                    </Box>
                    <Typography sx={classes.serviceDescription}>Quickly check if a place has the amenties or services that will make your life easier, such as praying spaces and halal food.</Typography>
                </Box>
                <Box sx={classes.serviceBox}>
                    <Box sx={classes.serviceHeader}>
                        <ReviewsIcon fontSize="large"/>
                        <Typography>User Reviews</Typography>
                    </Box>
                    <Typography sx={classes.serviceDescription}>See what other people think of this place and how was theri experience. Please share your feedback as well to places you ben at.</Typography>
                </Box>
                <Box sx={classes.comingSoon}>
                    <Box sx={classes.serviceHeader}>
                        <ThreePIcon fontSize="large"/>
                        <Typography>Customized</Typography>
                    </Box>
                    <Typography sx={classes.gold}>Coming Soon</Typography>
                    <Typography sx={classes.serviceDescription}>See which places are muslim friendly customized to your preferneces such as hand slaughtered, female-only, etc.</Typography>
                </Box>
                <Box sx={classes.comingSoon}>
                    <Box sx={classes.serviceHeader}>
                        <VerifiedUserIcon fontSize="large"/>
                        <Typography>Verified</Typography>
                    </Box>
                    <Typography sx={classes.gold}>Coming Soon</Typography>
                    <Typography sx={classes.serviceDescription}>Be at ease that the information you see on Muslim Friendly is not just user collected but also verified.</Typography>
                </Box>
            </Box>
        </Box>
    )

}