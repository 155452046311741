import React from "react";
import { Typography, Box } from "@mui/material";

const classes = {
        header: {
        height: {md:'5rem', xs: '5rem'}, 
        color: "white",
        background: "#0071A8",
        px: {md: "24px", xs: "15px"}, 
        borderTop:  '2px solid #f2f2f2', 
        borderBottom:  '2px solid #f2f2f2', 
        pt:"2rem"

    }, 
};

export default function PageHeader({header}) {
    return (
        <Box sx={classes.header}>
            <Typography component={"h3"} variant="h4" sx={classes.h3}>
                {header}
            </Typography>
        </Box>
    )
}